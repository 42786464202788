/* src/app/shared/components/spinner/spinkit-css/sk-line-material.scss */
.sk-line-material {
  top: 0;
  position: relative;
  margin: auto;
  width: 100%;
}
.sk-line-material .sk-child {
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0;
  display: inline-block;
  transform-origin: 0% 0%;
  -webkit-animation: sk-line-material 2s ease-in-out 0s infinite both;
  animation: sk-line-material 2s ease-in-out 0s infinite both;
}
@-webkit-keyframes sk-line-material {
  0%, 80%, 100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  40% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes sk-line-material {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
#http-loader {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
}
.loader-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  filter: alpha(opacity=70);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
}
.colored-parent,
.colored > div {
  background-color: rgba(26, 188, 156, 0.8);
}
/*# sourceMappingURL=sk-line-material-5T26VVJG.css.map */
