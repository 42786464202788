<li [ngClass]="item.classes!" *ngIf="item.url && !item.external" [routerLinkActive]="['active']">
  <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
    <span *ngIf="item.icon" class="coded-micon"><i [ngClass]="item.icon"></i></span>
    <span class="coded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
    <ng-template #directTitle>
      {{ item.title }}
    </ng-template>
  </a>
</li>
<li [ngClass]="item.classes!" *ngIf="item.url && item.external">
  <a [target]="item.target ? '_blank' : '_self'" [href]="item.url">
    <span *ngIf="item.icon" class="coded-micon"><i [ngClass]="item.icon"></i></span>
    <span class="coded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
    <ng-template #directTitle>
      {{ item.title }}
    </ng-template>
  </a>
</li>
