import { UserService } from '@/src/app/admin/user-mng/user.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { AppToastService } from '../../services/app.toast.service';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  @Output() response: EventEmitter<any> = new EventEmitter();
  @Input() materailMaps: any = [];
  newFormSubmitted = false;
  
  recordId: any;
  dataLoadingFlag: boolean = true;
  data: any = {};

  newForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    oldPassword: ['', Validators.required],
    newPassword: ['', Validators.required],
    confirmPassword: ['', Validators.required],
	});

  ltStocks: any = [];
  mColors: any = [];
  mFinishTextures: any = [];
  mFinishTypes: any = [];
  mMaterials: any = [];

  constructor(private activeModal: NgbActiveModal,
              public service: UserService,
              private toastService: AppToastService,
              public authService: AuthService,
              private cdref: ChangeDetectorRef,
              private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.newForm.patchValue({
      username: this.authService?.getUserDetails()?.username
    })
  }

  submitForm() {
    console.log('Submit Form...');
    console.log('Form Data: ', this.newForm.value)
    const data = this.newForm.getRawValue();

    this.newFormSubmitted = true;
    if (this.newForm.invalid) {
      return;
    }

    if(data.newPassword != data.confirmPassword) {
      this.toastService.show({ header: 'Invalid', body: 'New Password and Confirm Password do not match.', classname: 'bg-danger text-light', delay: 10000 });
      return;
    }

    this.authService.changePassword(data).subscribe({
      next: (res: any) => {
        console.log('data', res);
        const materialData = res.data?.createMaterialMap;
        // if(materialData) {
          this.response.emit();
          this.closeModal();
        // }
        this.cdref.detectChanges();
      },
      error: (err: any) => {
        console.error('err', err);
        if(err?.status == 400 || err?.status == 404) {
          this.toastService.show({ header: 'Failed', body: err?.error?.message, classname: 'bg-danger text-light', delay: 10000 });
        } else {
          this.toastService.show({ header: 'Failed', body: 'Please try Again', classname: 'bg-danger text-light', delay: 10000 });
        }
        this.cdref.detectChanges();
      },
    });
  }
  
  closeModal() {
    this.activeModal.close();
    // activeModal.dismiss('Cross click');
  }
}
