import { Component } from '@angular/core';
import { AppToastService } from '../../services/app.toast.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-toasts',
  standalone: true,
  imports: [NgbToastModule],
  templateUrl: './app-toasts.component.html',
  styleUrl: './app-toasts.component.scss'
})
export class AppToastsComponent {
  constructor(public toastService: AppToastService) {}
}
