<div class="card">
  <div class="card-header">
    @if (!customHeader) {
      <h5>{{ cardTitle }}</h5>
    }
  </div>
  <div class="card-block">
    <ng-content></ng-content>
  </div>
</div>
