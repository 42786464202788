<div class="modal-header">
	<h4 class="modal-title">Change Password</h4>
	<button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<form [formGroup]="newForm">
	<div class="modal-body row g-3">
		<div class="col-md-12">
			<div class="row g-3">
				<div class="col-md-12">
					<label for="inputState" class="form-label">Username</label>
					<div class="form-control"> {{ newForm.get('username').value }}</div>
				</div>
				<div class="col-md-12">
					<label for="inputState" class="form-label">Password</label>
					<input type="password" class="form-control" formControlName="oldPassword" required [ngClass]="{ 'is-invalid': newFormSubmitted && newForm.controls['oldPassword'].errors }">
				</div>
				<hr>
        <div class="col-md-12">
					<label for="inputState" class="form-label">New Password</label>
					<input type="password" class="form-control" formControlName="newPassword" required [ngClass]="{ 'is-invalid': newFormSubmitted && newForm.controls['newPassword'].errors }">
				</div>
        <div class="col-md-12">
					<label for="inputState" class="form-label">Confirm New Password</label>
					<input type="password" class="form-control" formControlName="confirmPassword" required [ngClass]="{ 'is-invalid': newFormSubmitted && newForm.controls['confirmPassword'].errors }">
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="submitForm()">Update</button>
		<button type="button" class="btn btn-outline-dark" (click)="closeModal()">Close</button>
	</div>
</form>