/* src/app/shared/components/spinner/spinner.component.scss */
#http-loader {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
}
.loader-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  filter: alpha(opacity=70);
  opacity: 0.7;
  background-color: #f1f1f1;
}
.colored-parent,
.colored > div {
  background-color: #333;
}
/*# sourceMappingURL=spinner.component-IHKLASXT.css.map */
