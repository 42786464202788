import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { NavRightComponent } from './nav-bar/nav-right/nav-right.component';
import { NavLogoComponent } from './nav-bar/nav-logo/nav-logo.component';
import { NavLeftComponent } from './nav-bar/nav-left/nav-left.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NavContentComponent } from './navigation/nav-content/nav-content.component';
import { NavItemComponent } from './navigation/nav-content/nav-item/nav-item.component';
import { NavGroupComponent } from './navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './navigation/nav-content/nav-collapse/nav-collapse.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';



@NgModule({
  declarations: [
    NavigationComponent, 
    ConfigurationComponent, 
    NavBarComponent,
    NavRightComponent, 
    NavLogoComponent, 
    NavLeftComponent,
    NavContentComponent,
    NavItemComponent,
    NavGroupComponent,
    NavCollapseComponent,
    
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    NgScrollbarModule,
    SharedModule,
    BreadcrumbsComponent
  ],
  exports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    NgScrollbarModule,
    NavigationComponent, 
    ConfigurationComponent, 
    NavBarComponent,
    NavRightComponent, 
    NavLogoComponent, 
    NavLeftComponent,
    NavContentComponent,
    NavItemComponent,
    NavGroupComponent,
    NavCollapseComponent,
    BreadcrumbsComponent,
  ],
})
export class AdminLayoutModule { }
