import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  icon?: string;
  url?: string;
  classes?: string;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}
const NavigationItems = [
  {
    id: 'dashboard',
    title: 'Home',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'home',
        title: 'Home',
        type: 'item',
        classes: 'nav-item',
        url: '/home',
        icon: 'ti ti-home',
        breadcrumbs: false
      },
      {
        id: 'intouch-progress',
        title: 'InTouch → Avery',
        type: 'item',
        classes: 'nav-item',
        url: '/progress/intouch',
        icon: 'ti ti-anchor',
        breadcrumbs: false
      },
      {
        id: 'avery-progress',
        title: 'Avery → InTouch',
        type: 'item',
        classes: 'nav-item',
        url: '/progress/avery',
        icon: 'ti ti-anchor',
        breadcrumbs: false
      },
      {
        id: 'orders',
        title: 'Order History',
        type: 'item',
        classes: 'nav-item',
        url: '/orders',
        icon: 'ti ti-files',
        breadcrumbs: false
      }
    ]
  },
  // {
  //   id: 'page',
  //   title: 'Reports',
  //   type: 'group',
  //   icon: 'icon-navigation',
  //   children: [
  //     {
  //       id: 'settings',
  //       title: 'Settings',
  //       type: 'collapse',
  //       icon: 'ti ti-settings',
  //       children: [
  //         {
  //           id: 'login',
  //           title: 'Materials Mapping',
  //           type: 'item',
  //           url: '/settings/material-map',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         // {
  //         //   id: 'register',
  //         //   title: 'Roles',
  //         //   type: 'item',
  //         //   url: '/settings/r',
  //         //   target: true,
  //         //   breadcrumbs: false
  //         // }
  //       ]
  //     },
  //   ]
  // },
  {
    id: 'page',
    title: 'Config',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'settings',
        title: 'Settings',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
          {
            id: 'materials',
            title: 'Materials Mapping',
            type: 'item',
            url: '/settings/material-map',
            // target: true,
            breadcrumbs: true
          },
          {
            id: 'products',
            title: 'Products Mapping',
            type: 'item',
            url: '/settings/product-map',
            // target: true,
            breadcrumbs: true
          },
          {
            id: 'dies',
            title: 'Dies Mapping',
            type: 'item',
            url: '/settings/die-map',
            // target: true,
            breadcrumbs: true
          },
          // {
          //   id: 'ltCustomer',
          //   title: 'LT Customers',
          //   type: 'item',
          //   url: '/settings/lt-customer',
          //   // target: true,
          //   breadcrumbs: true
          // },
          {
            id: 'ltProduct',
            title: 'LT Products',
            type: 'item',
            url: '/settings/lt-product',
            // target: true,
            breadcrumbs: true
          },
          {
            id: 'ltStock',
            title: 'LT Stocks',
            type: 'item',
            url: '/settings/lt-stock',
            // target: true,
            breadcrumbs: true
          },
          {
            id: 'ltTooling',
            title: 'LT Tooling',
            type: 'item',
            url: '/settings/lt-tooling',
            // target: true,
            breadcrumbs: true
          },
          {
            id: 'magentoMaterial',
            title: 'Magento Materials',
            type: 'item',
            url: '/settings/magento-material',
            // target: true,
            breadcrumbs: true
          },
          {
            id: 'magentoProduct',
            title: 'Magento Products',
            type: 'item',
            url: '/settings/magento-product',
            // target: true,
            breadcrumbs: true
          },
          {
            id: 'magentoDie',
            title: 'Magento Dies',
            type: 'item',
            url: '/settings/magento-die',
            // target: true,
            breadcrumbs: true
          },
          // {
          //   id: 'dies',
          //   title: 'Dies Mapping',
          //   type: 'item',
          //   url: '/settings/die-map',
          //   // target: true,
          //   breadcrumbs: true
          // }
        ]
      },
    ]
  },
  {
    id: 'page',
    title: 'Admin',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Authentication',
        title: 'Authentication',
        type: 'collapse',
        icon: 'ti ti-key',
        children: [
          {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/admin/users',
            // target: true,
            breadcrumbs: true
          },
          // {
          //   id: 'register',
          //   title: 'Roles',
          //   type: 'item',
          //   url: '/admin/roles',
          //   // target: true,
          //   breadcrumbs: false
          // }
        ]
      }
    ]
  },
  // {
  //   id: 'elements',
  //   title: 'Elements',
  //   type: 'group',
  //   icon: 'icon-navigation',
  //   children: [
  //     {
  //       id: 'typography',
  //       title: 'Typography',
  //       type: 'item',
  //       classes: 'nav-item',
  //       url: '/typography',
  //       icon: 'ti ti-typography'
  //     },
  //     {
  //       id: 'color',
  //       title: 'Colors',
  //       type: 'item',
  //       classes: 'nav-item',
  //       url: '/color',
  //       icon: 'ti ti-brush'
  //     },
  //     {
  //       id: 'tabler',
  //       title: 'Tabler',
  //       type: 'item',
  //       classes: 'nav-item',
  //       url: 'https://tabler-icons.io/',
  //       icon: 'ti ti-plant-2',
  //       target: true,
  //       external: true
  //     }
  //   ]
  // },
  // {
  //   id: 'other',
  //   title: 'Other',
  //   type: 'group',
  //   icon: 'icon-navigation',
  //   children: [
  //     {
  //       id: 'sample-page',
  //       title: 'Sample Page',
  //       type: 'item',
  //       url: '/sample-page',
  //       classes: 'nav-item',
  //       icon: 'ti ti-brand-chrome'
  //     },
  //     {
  //       id: 'document',
  //       title: 'Document',
  //       type: 'item',
  //       classes: 'nav-item',
  //       url: 'https://codedthemes.gitbook.io/berry-angular/',
  //       icon: 'ti ti-vocabulary',
  //       target: true,
  //       external: true
  //     }
  //   ]
  // }
];

@Injectable({
  providedIn: 'root'
})
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
