@for (toast of toastService.toasts; track toast) {
  <ngb-toast
				[class]="toast.classname"
				[autohide]="true"
				[delay]="toast.delay || 5000"
				(hidden)="toastService.remove(toast)">
      @if(toast.header) {
        <h6>{{ toast.header }}</h6>
      }
      @if(toast.body) {
        <span>{{ toast.body }}</span>
      }
    </ngb-toast>
}