// Angular import
import { ChangePasswordComponent } from '@/src/app/shared/components/change-password/change-password.component';
import { AuthService } from '@/src/app/shared/services/auth.service';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent {

  constructor(public authService: AuthService, private modalService: NgbModal) {}
  changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      centered: true,
      size: 'md',
      backdrop: true,
      keyboard: false,
    });
		modalRef.componentInstance.response.subscribe(() => {
			this.logout();
		});
  }

  logout() {
    this.authService.logout();
  }
}
