import { Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthGuard } from './shared/guard/auth.guard';

export const routes: Routes = [
	{ 
    path: '', 
    component: AdminLayoutComponent, 
    data: { title: 'Main Views' }, 
    canActivate: [AuthGuard],
    children: [
      {
				path: '',
				redirectTo: '/home',
				pathMatch: 'full',
			},
      {
        path: 'home',
        component: HomeComponent,
      },
      {
				path: 'progress',
				loadChildren: () => import('./progress/progress.module').then((m) => m.ProgressModule),
			},
      {
				path: 'orders',
				loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersModule),
			},
      {
				path: 'reports',
				loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
			},
      {
				path: 'settings',
				loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
			},
      {
				path: 'admin',
				loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
			},
    ]
  },
  {
		path: 'login',
		loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule)
	},
  { path: '**', redirectTo: '/', pathMatch: 'full' }
	// { path: '**', component: NotFoundComponent }
];
