import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

// Bootstrap Import
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

// third party
import { NgApexchartsModule } from 'ng-apexcharts';
import ApexCharts from 'apexcharts';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexGrid,
  ApexStroke,
  ApexTooltip
} from 'ng-apexcharts';
import { SharedModule } from '../shared/shared.module';
import { LtHomeService } from './home.service';
import { ReportService } from '../reports/report.service';
import { Subscription, interval } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  colors: string[];
  grid: ApexGrid;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SharedModule, NgApexchartsModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  private refreshSubscription: Subscription;
  refreshInterval: number = 30000; // Default refresh interval in milliseconds

  data: any = {
    averyProgressCount: 0,
    intouchProgressCount: 0,
    customerCount: 0,
    orderCount: 0
  };

  // private props
  @ViewChild('intouchChart') intouchChart: ChartComponent;
  intouchChartOptions: Partial<ChartOptions>;
  intouchChartLoading = true;

  @ViewChild('averyChart') averyChart: ChartComponent;
  averyChartOptions: Partial<ChartOptions>;
  monthChart: any;
  yearChart: any;
  colorChart = ['#673ab7'];

  
  // Constructor
  constructor(public service: LtHomeService,
              public reportService: ReportService,
              private cdref: ChangeDetectorRef) {
    this.intouchChartOptions = {
      series: [{
        name: 'Count',
        data: []
      }],
      dataLabels: {
        enabled: true,
      },
      chart: {
        type: 'bar',
        height: 480,
        toolbar: {
          show: true
        }
      },
      colors: [
        "#008FFB",
        "#FEB019",
        "#26a69a",
        "#546E7A",
        "#FF4560"
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          // horizontal: false,
          distributed: true,
          columnWidth: '50%'
        }
      },
      xaxis: {
        type: 'category',
        categories: [],
        labels: {
          style: {
            colors: [
              "#008FFB",
              "#FEB019",
              "#26a69a",
              "#546E7A",
              "#FF4560",
            ],
            fontSize: "12px"
          }
        }
      },
      grid: {
        strokeDashArray: 4
      },
      tooltip: {
        theme: 'dark'
      }
    };
    this.averyChartOptions = {
      series: [],
      dataLabels: {
        enabled: false
      },
      chart: {
        type: 'bar',
        height: 480,
        stacked: true,
        toolbar: {
          show: true
        }
      },
      colors: ['#90caf9', '#1e88e5', '#673ab7', '#ede7f6'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%'
        }
      },
      xaxis: {
        type: 'category',
        categories: []
      },
      grid: {
        strokeDashArray: 4
      },
      tooltip: {
        theme: 'dark'
      }
    };
  }

  // Life cycle events
  ngOnInit(): void {
    this.fetchData();
    // setTimeout(() => {
    //   this.monthChart = new ApexCharts(document.querySelector('#tab-chart-1'), this.monthOptions);
    //   this.monthChart.render();
    // }, 500);
    this.startAutoRefresh();
  }

  ngOnDestroy(): void {
    this.stopAutoRefresh();
  }

  startAutoRefresh(): void {
    this.refreshSubscription = interval(this.refreshInterval).subscribe(data => {
      this.fetchData();
    });
  }

  stopAutoRefresh(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  changeRefreshInterval(interval: number): void {
    this.refreshInterval = interval;
    this.stopAutoRefresh();
    this.startAutoRefresh();
  }

  fetchData() {
    this.getCounts();

    this.intouchChartLoading = true;
    this.getProgressStatusCount();
  }

  getCounts() {
    // this.dataLoadingFlag = true;
    // this.spinner.show();
    this.service.summaryCount().subscribe({
      next: (data:any) => {
        console.log("data from server ", data?.data);
        this.data = data?.data;
        // if(data?.data?.paginateLtCustomers) {
        //   this.records.push(...data.data.paginateLtCustomers.items);
        //   this.filters.lastKey = data.data.paginateLtCustomers.lastKey;
        // }
        // this.renderPage();

        // this.dataLoadingFlag = false;
        // this.spinner.hide();
        // this.cdref.detectChanges();
      },
      error: (err: any) => {
        // this.spinner.hide();
        console.log('data error: ', err);
        // this.cdref.detectChanges();
        // this.dataLoadingFlag = false;
      }
    })
  }

  getProgressStatusCount() {
    this.intouchChartOptions.series[0].data = [];
    this.intouchChartOptions.xaxis.categories = [];
    
    // this.dataLoadingFlag = true;
    // this.spinner.show();
    this.reportService.progressStatusCount().subscribe({
      next: (data:any) => {
        console.log("data from server ", data);
        this.renderInTouchChartData(data['InTouch']);
        this.renderAveryChartData(data['Avery']);
        // this.dataLoadingFlag = false;
        // this.spinner.hide();
        this.cdref.detectChanges();
      },
      error: (err: any) => {
        // this.spinner.hide();
        console.log('data error: ', err);
        // this.cdref.detectChanges();
        // this.dataLoadingFlag = false;
      }
    })
  }

  renderInTouchChartData(data: any) {
    for (const key in data) {
      const value = data[key];
      this.intouchChartOptions.series[0].data.push(value);
      this.intouchChartOptions.xaxis.categories.push(key);
      // if (Object.prototype.hasOwnProperty.call(data, key)) {
      //   const value = data[key];
        
      // }
    }
    // this.intouchChart.render();
    console.log(this.intouchChartOptions)
    this.intouchChartLoading = false;
    
  }

  renderAveryChartData(data: any) {
    
  }

  // public Method
  // onNavChange(changeEvent: NgbNavChangeEvent) {
  //   if (changeEvent.nextId === 1) {
  //     setTimeout(() => {
  //       this.monthChart = new ApexCharts(document.querySelector('#tab-chart-1'), this.monthOptions);
  //       this.monthChart.render();
  //     }, 200);
  //   }

  //   if (changeEvent.nextId === 2) {
  //     setTimeout(() => {
  //       this.yearChart = new ApexCharts(document.querySelector('#tab-chart-2'), this.yearOptions);
  //       this.yearChart.render();
  //     }, 200);
  //   }
  // }

  // ListGroup = [
  //   {
  //     name: 'Bajaj Finery',
  //     profit: '10% Profit',
  //     invest: '$1839.00',
  //     bgColor: 'bg-light-success',
  //     icon: 'ti ti-chevron-up',
  //     color: 'text-success'
  //   },
  //   {
  //     name: 'TTML',
  //     profit: '10% Loss',
  //     invest: '$100.00',
  //     bgColor: 'bg-light-danger',
  //     icon: 'ti ti-chevron-down',
  //     color: 'text-danger'
  //   },
  //   {
  //     name: 'Reliance',
  //     profit: '10% Profit',
  //     invest: '$200.00',
  //     bgColor: 'bg-light-success',
  //     icon: 'ti ti-chevron-up',
  //     color: 'text-success'
  //   },
  //   {
  //     name: 'ATGL',
  //     profit: '10% Loss',
  //     invest: '$189.00',
  //     bgColor: 'bg-light-danger',
  //     icon: 'ti ti-chevron-down',
  //     color: 'text-danger'
  //   },
  //   {
  //     name: 'Stolon',
  //     profit: '10% Profit',
  //     invest: '$210.00',
  //     bgColor: 'bg-light-success',
  //     icon: 'ti ti-chevron-up',
  //     color: 'text-success'
  //   }
  // ];

  // monthOptions = {
  //   chart: {
  //     type: 'line',
  //     height: 90,
  //     sparkline: {
  //       enabled: true
  //     }
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   colors: ['#FFF'],
  //   stroke: {
  //     curve: 'smooth',
  //     width: 3
  //   },
  //   series: [
  //     {
  //       name: 'series1',
  //       data: [45, 66, 41, 89, 25, 44, 9, 54]
  //     }
  //   ],
  //   yaxis: {
  //     min: 5,
  //     max: 95
  //   },
  //   tooltip: {
  //     theme: 'dark',
  //     fixed: {
  //       enabled: false
  //     },
  //     x: {
  //       show: false
  //     },
  //     y: {
  //       title: {
  //         formatter: function (seriesName: any) {
  //           return 'Total Earning';
  //         }
  //       }
  //     },
  //     marker: {
  //       show: false
  //     }
  //   }
  // };

  // yearOptions = {
  //   chart: {
  //     type: 'line',
  //     height: 90,
  //     sparkline: {
  //       enabled: true
  //     }
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   colors: ['#FFF'],
  //   stroke: {
  //     curve: 'smooth',
  //     width: 3
  //   },
  //   series: [
  //     {
  //       name: 'series1',
  //       data: [35, 44, 9, 54, 45, 66, 41, 69]
  //     }
  //   ],
  //   yaxis: {
  //     min: 5,
  //     max: 95
  //   },
  //   tooltip: {
  //     theme: 'dark',
  //     fixed: {
  //       enabled: false
  //     },
  //     x: {
  //       show: false
  //     },
  //     y: {
  //       title: {
  //         formatter: function (seriesName: any) {
  //           return 'Total Earning';
  //         }
  //       }
  //     },
  //     marker: {
  //       show: false
  //     }
  //   }
  // };
}
