import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GraphqlService } from "../shared/services/graphql.service";

@Injectable({ providedIn: 'root' })
export class LtHomeService {
  constructor(private http: HttpClient, private graphqlService: GraphqlService) {}

  summaryCount() {
    const variables = { };
    const query = `{
      customerCount(param: "")
      averyOrderCount: orderCount(param: "source", value: "Avery")
      intouchOrderCount: orderCount(param: "source", value: "InTouch")
      averyProgressCount: progressCount(param: "source", value: "Avery")
      intouchProgressCount: progressCount(param: "source", value: "InTouch")
    }`;

    return this.graphqlService.query(query, variables);
  }

}