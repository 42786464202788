<!-- [ Sidebar Menu ] start -->
<nav>
  <div class="navbar-wrapper">
    <div class="m-header">
      <!-- [ Header Topbar ] start -->
      <a [routerLink]="['/default/']" class="b-brand">
        <!-- ========   change your logo hear   ============ -->
        <img src="assets/images/logo-dark.svg" class="logo logo-lg" />
      </a>
    </div>
    <app-nav-content (NavCollapsedMob)="navCollapseMob()" class="scroll-div w-100"></app-nav-content>
  </div>
</nav>
<!-- [ Sidebar Menu ] end -->
