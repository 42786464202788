import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GraphqlService } from "../shared/services/graphql.service";
import { environment } from "@/src/environments/environment";

@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private http: HttpClient, private graphqlService: GraphqlService) {}

  progressStatusCount() {
    return this.http.post(`${environment.apiUrlLabelTraxxService}/generate-report`, {report: 'PROGRESS_STATUS_COUNT'});
  }

}